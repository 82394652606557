<template>
  <div class="app-container">
    <eHeader  :query="query" :typeOptions="typeOptions" :statusOptions="statusOptions"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="channelName" label="渠道名称"/>
      <el-table-column prop="channelCode" label="渠道编码"/>
      <el-table-column prop="channelType" label="支付类型">
         <template slot-scope="scope">
          <span>{{ parseChannelType(scope.row.channelType) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="channelBankCode" label="支付渠道"/>
      <el-table-column prop="channelProtocolNo" label="渠道协议号"/>
      <el-table-column prop="channelProtocolName" label="渠道协议名称"/>
      <el-table-column prop="channelBankAccount" label="提现账户"/>
      <el-table-column prop="channelValidTime" label="渠道有效期">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.channelValidTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="渠道状态">
        <template slot-scope="scope">
          <span>{{ statusOptions[scope.row.channelStatus].label}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注"/>
      <el-table-column label="操作"  align="center">
        <template slot-scope="scope">
          <edit v-if="checkPermission(['ADMIN','CHANNEL_ALL','CHANNEL_EDIT'])" :data="scope.row" :sup_this="sup_this" :typeOptions="typeOptions" :statusOptions="statusOptions"/>
          <el-popover
            v-if="checkPermission(['ADMIN','CHANNEL_ALL','CHANNEL_UPDATE_STAUS'])"
            :ref="scope.row.id"
            placement="top"
            width="180">
            <p>确定{{ scope.row.channelStatus!=1 ? '启用':'禁用' }}此商户吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="modifyStatus(scope.row)">确定</el-button>
            </div>
            <el-button slot="reference" type="danger" size="mini">{{ scope.row.channelStatus!=1 ? '启用':'禁用' }}</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initPmsData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/pms/channel/header'
import edit from '@/components/pms/channel/edit'
import { updateChannelInfo } from '@/api/dataPms'

export default {
  components: { eHeader, edit },
  mixins: [initData],
  data() {
    return {
      delLoading: false, sup_this: this,formData:{},
      typeOptions: [
        { value: '1', label: '代扣业务' },
        { value: '2', label: '代付业务'},
        { value: '3', label: '认证支付'},
        { value: '4', label: '协议支付'},
        { value: '5', label: '批量代扣'},
        { value: '6', label: '出金业务'},
        { value: '7', label: '入金业务'}
      ],
      statusOptions:[
        {value:0,label:'未启用'},
        {value:1,label:'启用'},
        {value:2,label:'禁用'}
        ]
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = '/community/crm/configure/channel/queryChannelInfoByParams'
      const query = this.query
      const channelName = query.channelName
      const channelCode = query.channelCode
      const channelType=query.channelType
      const channelStatus=query.channelStatus
      const startValidDate = parseTime(query.startValidDate)
      const endValidDate = parseTime(query.endValidDate)
      this.formData = { P: this.page, pageSize: this.size }
      if (channelName !== '' && channelName !== null) { this.formData['channelName'] = channelName }
      if (channelCode !== '' && channelCode !== null) { this.formData['channelCode'] = channelCode }
      if (channelType !== '' && channelType !== null) { this.formData['channelType'] = channelType }
      if (channelStatus !== '' && channelStatus !== null) { this.formData['channelStatus'] = channelStatus }
      if (startValidDate !== '' && startValidDate !== null) { this.formData['startValidDate'] = startValidDate }
      if (endValidDate !== '' && endValidDate !== null) { this.formData['endValidDate'] = parseTime(endValidDate) }
      this.params = {'content':JSON.stringify(this.formData)}
      return true
    },
    parseChannelType(channelType){
      var channel=''
      if(channelType !== '' && channelType !== null){
        var channelTypes=channelType.split(',')
        for (var i = channelTypes.length - 1; i >= 0; i--) {
          channel = channel +this.typeOptions[i].label
          if(i!=0){
            channel = channel +','
          }
        }
      }
      return channel
    },
    parseStstus(status){
      return this.statusOptions[status].label;
    },
    modifyStatus(info) {
      this.delLoading = true
      info.channelStatus=info.channelStatus!=1 ? '1':'2'
      this.formData = { channelInfo: info }
      this.params = {'content':JSON.stringify(this.formData)}
      updateChannelInfo(this.params).then(res => {
        this.delLoading = false
        this.$refs[info.id].doClose()
        this.init()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
        this.delLoading = false
        this.$refs[id].doClose()
        console.log(err.response.data.message)
      })
    }
  }
}
</script>

<style scoped>

</style>
