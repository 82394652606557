<template>
  <el-dialog :visible.sync="dialog" :title="isAdd ? '新增渠道' : '编辑渠道'" append-to-body width="700px">
    <el-form ref="form" :model="form" size="small" label-width="120px">
      <el-form-item label="渠道名称" prop="channelName">
        <el-input v-model="form.channelName" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="渠道编码" prop="channelCode">
        <el-input v-model="form.channelCode" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="支付类型" prop="channelType">
        <el-select v-model="channelTypes" multiple placeholder="请选择支付类型">
        <el-option
          v-for="item in typeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      </el-form-item>
      <el-form-item label="支付渠道" prop="channelBankCode">
        <el-input v-model="form.channelBankCode" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="渠道协议号" prop="channelProtocolNo">
        <el-input v-model="form.channelProtocolNo" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="渠道协议名称" prop="channelProtocolName">
        <el-input v-model="form.channelProtocolName" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="提现账户" prop="channelBankAccount">
        <el-input v-model="form.channelBankAccount" style="width: 500px;"/>
      </el-form-item>
      <el-form-item label="有效期" prop="channelValidTime">
        <el-date-picker
          v-model="form.channelValidTime"
          type="date"
          style="width: 250px;"
          placeholder="选择日期">
        </el-date-picker>
      </el-form-item> 
      <el-form-item label="渠道状态" prop="channelStatus">
        <el-select v-model="form.channelStatus" placeholder="请选择渠道状态">
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="form.remark" style="width: 500px;"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addChannelInfo, updateChannelInfo } from '@/api/dataPms'
import { parseTime } from '@/utils/index'
export default {
  name: 'Form',
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    },
    typeOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    }
    
  },
  data() {
    return {
      dialog: false, loading: false, form: {id: '',channelName: '', channelCode: '', channelType: '', channelBankCode: '',channelProtocolNo: '',channelProtocolName: '',channelBankAccount: '', channelStatus:'' , channelValidTime: '' ,remark: '',updateBy:'',createBy:''},  channelTypes:[],
      rules: {
        channelName: [
          { required: true, message: '请输入渠道名称', trigger: 'blur' }
        ],
        channelCode: [
          { required: true, message: '请输入渠道编码', trigger: 'blur' }
        ],
        channelBankCode: [
          { required: true, message: '请输入支付渠道', trigger: 'blur' }
        ],
        channelProtocolNo: [
          { required: true, message: '请输入渠道协议号', trigger: 'blur' }
        ],
        channelProtocolName: [
          { required: true, message: '请输入渠道名称', trigger: 'blur' }
        ],
        channelBankAccount: [
          { required: true, message: '请输入提现银行账户', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.isAdd) {
            this.doAdd()
          } else {
            this.doEdit()
          } 
        } else {
          return false
        }
      })
    },
    doAdd() {
      this.formatData()
      addChannelInfo(this.params).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      this.formatData()
      updateChannelInfo(this.params).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    formatData(){
      this.form.channelType=''
      for (var i = this.channelTypes.length - 1; i >= 0; i--) {
        this.form.channelType = this.form.channelType+this.channelTypes[i]
        if(i!=0){
          this.form.channelType = this.form.channelType+','
        }
      }
      const channelValidTime = this.form.channelValidTime
      this.form.channelValidTime=parseTime(channelValidTime)
      if(this.isAdd) {
        if(this.form.updateBy=='' || this.form.updateBy==null ) this.form.updateBy='System'
        if(this.form.createBy=='' || this.form.createBy==null ) this.form.createBy='System'
      }
      this.formData = { channelInfo: this.form }
      this.params = {'content':JSON.stringify(this.formData)}
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.channelTypes=[]
      this.form =  {id: '',channelName: '', channelCode: '', channelType: '', channelBankCode: '',channelProtocolNo: '',channelProtocolName: '',channelBankAccount: '', channelStatus:'' , channelValidTime: '' ,remark: ''}
    }
  }
}
</script>

<style scoped>

</style>
