<template>
  <div>
    <el-button size="mini" type="success" @click="to">编辑</el-button>
    <eForm ref="form"  :sup_this="sup_this" :is-add="false" :typeOptions="typeOptions" :statusOptions="statusOptions"/>
  </div>
</template>
<script>
import eForm from './form'
export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    // index.vue 的this 可用于刷新数据
    sup_this: {
      type: Object,
      required: true
    },
    typeOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    }
  },
  methods: {
    to() {
      const _this = this.$refs.form
      _this.form ={ id:this.data.id,channelName: this.data.channelName, channelCode: this.data.channelCode, channelType: this.data.channelType,channelBankCode:this.data.channelBankCode,  channelProtocolNo:this.data.channelProtocolNo, channelProtocolName: this.data.channelProtocolName ,channelBankAccount: this.data.channelBankAccount,channelStatus:this.data.channelStatus,channelValidTime:this.data.channelValidTime,remark:this.data.remark}
      if(this.data.channelType!=null && this.data.channelType!=''){
        _this.channelTypes=this.data.channelType.split(',')
      }      
      _this.typeOptions=this.typeOptions
      _this.statusOptions=this.statusOptions
      _this.dialog = true 
    }
  }
}
</script>

<style scoped>
  div{display: inline-block;margin-right: 3px;}
</style>
